<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="$bvModal.show('eventModal')"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col
                  md="12"
                  class="d-flex mb-2"
                >
                  <h4
                    class="mb-0 mr-1"
                    style="padding-top:2px"
                  >
                    <strong>Open To: </strong>
                  </h4>
                  <b-form-checkbox
                    v-b-tooltip="event.public ? 'Disable Public' : 'Make Public'"
                    :checked="event.public"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                    @change="updateStatus(!event.public)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                  <div class="d-flex align-items-center">
                    <b-badge
                      :variant="event.public?'light-primary':'light-danger'"
                      style="text-transform: capitalize;font-size:16px;"
                    >
                      <strong>{{ event.public ? "Public" : "Inhouse/Restricted" }}</strong>
                    </b-badge>
                  </div>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Event Type"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      label="Event Type"
                      label-for="eventType"
                    >
                      <v-select
                        id="eventType"
                        v-model="event.type"
                        :options="['Workshop', 'Masterclass','Bootcamp']"
                        label="Event Type"
                        placeholder="Event Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Intended Participation"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      label="Intended Participants"
                      label-for="intendedParticipation"
                    >
                      <v-select
                        id="intendedParticipation"
                        v-model="event.participation"
                        :options="['Startups', 'Mentors', ]"
                        multiple
                        label="Intended Participants"
                        placeholder="Intended Participants"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Event Title"
                    label-for="eventTitle"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Event Title"
                      rules="required"
                    >
                      <b-form-input
                        id="eventTitle"
                        v-model="event.title"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Title"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Delivery Mode"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      label="Delivery Mode"
                      label-for="deliveryMode"
                    >
                      <v-select
                        id="deliveryMode"
                        v-model="event.deliveryMode"
                        :options="['Offline', 'Online', 'Hybrid']"
                        label="Delivery Mode"
                        placeholder="Delivery Mode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    class="mb-2"
                    label="Select Start date & time"
                    label-for="SelectStartDateAndTime"
                  >
                    <flat-pickr
                      id="SelectStartDateAndTime"
                      v-model="event.beginTime"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                      class="form-control"
                      placeholder="Select start date & time"
                    />
                    <!-- <b-form-datepicker
                      id="SelectStartDateAndTime"
                      today-button
                      reset-button
                      close-button
                      class="form-control"
                      locale="en"
                    ></b-form-datepicker> -->
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    class="mb-2"
                    label="Select End date & time"
                    label-for="SelectEndDateAndTime"
                  >
                    <flat-pickr
                      id="SelectEndDateAndTime"
                      v-model="event.endTime"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate:event.beginTime}"
                      class="form-control"
                      placeholder="Select end date & time"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Event Description"
                    label-for="eventDescription"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Event Description"
                      rules="required"
                    >
                      <b-form-input
                        id="eventDescription"
                        v-model="event.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Event Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Capacity"
                    label-for="eventCapacity"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Capacity"
                      rules="required"
                    >
                      <b-form-input
                        id="eventCapacity"
                        v-model="event.capacity"

                        :state="errors.length > 0 ? false:null"
                        placeholder="Number of participants"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <!-- Styled -->
                  <b-form-group
                    label="Upload Event Poster(Image)"
                    label-for="eventPoster"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="File"
                      rules="size:10000"
                    >
                      <b-form-file
                        v-model="event.file"
                        accept="image/jpeg, image/png, image/gif"
                        style="margin-bottom: 0.5rem;"
                        :state="errors.length > 0 ? false:null"
                        drop-placeholder="Drop file here..."
                        placeholder="Choose a file or drop it here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <h5 class="mb-0">
                    Deliverables
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(item, i) in event.deliverables"
                    :key="i"
                    class="mt-2"
                  >
                    <b-col>
                      <b-form-group
                        :label="`Deliverable ${i + 1}`"
                        :label-for="`Deliverable-${i + 1}`"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="`Deliverable ${i + 1}`"
                        >
                          <b-form-input
                            :id="`Deliverable-${i + 1}`"
                            v-model="item.deliverable"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Deliverable Title"
                            type="text"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="event.deliverables.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :class="{'mr-2': i === event.deliverables.length-1}"
                        class="mt-2"
                        variant="outline-danger"
                        @click="event.deliverables.splice(i, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="i === event.deliverables.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-primary"
                        @click="event.deliverables.push({deliverable: null, description: null})"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Deliverable</span>
                      </b-button>

                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="`Deliverable ${i + 1} Description`"
                      >
                        <b-form-input
                          :id="`description-${i + 1}`"
                          v-model="item.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Deliverable Description"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

        </form-wizard>

      </div>
    </b-col>
    <!-- modal starts -->
    <b-modal
      id="eventModal"
      :ok-title="`${(event.lifeCycleStage && event.industry && event.customerFocus && event.offeringsType && event.impactFocus && event.lifeCycleStage.length === 0 && event.industry.length === 0 && event.customerFocus.length === 0 && event.offeringsType.length === 0 && event.impactFocus.length === 0)?'Skip':'Save'}`"
      no-close-on-backdrop
      size="lg"
      title="Additional Details"
      @ok="handlenewsave"
    >
      <h4>You can select multiple options.</h4>
      <b-row>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="Ideal Startup Lifecycle Stage"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Ideal Startup Lifecycle Stage"
              label-for="lifeCycleStage"
            >
              <v-select
                id="lifeCycleStage"
                v-model="event.lifeCycleStage"
                :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                multiple
                placeholder="Select one or multiple options"
                style="z-index: 1000;"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="Participant Industry/Sector"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Participant Industry/Sector"
              label-for="industry"
            >

              <v-select
                id="industry"
                v-model="event.industry"
                :options="industries"
                multiple
                placeholder="Select one or multiple options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <h6><strong>Focus</strong></h6>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Customer Focus"
            label-for="customerFocus"
          >
            <v-select
              id="customerFocus"
              v-model="event.customerFocus"
              :options="['B2C', 'D2C', 'B2B', 'B2G', 'B2B2C', 'B2B2B', 'B2C2B', 'B2C2C', 'B2B2G', 'B2G2B', 'B2G2C', 'B2C2G']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Offerings Type"
            label-for="offeringsType"
          >
            <v-select
              id="offeringsType"
              v-model="event.offeringsType"
              :options="['Product', 'Service', 'Hybrid']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Impact Focus"
            label-for="impactFocus"
          >
            <v-select
              id="impactFocus"
              v-model="event.impactFocus"
              :options="['Profit', 'Social', 'Environmental']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="submitModal"
      ok-title="Yes, Submit"
      ok-variant="success"
      button-size="sm"
      no-close-on-backdrop
      centered
      size="sm"
      title="Confirm Submit"
      @ok="addEvent()"
      @cancel="$bvModal.show('eventModal')"
    >
      <h6>Adding additional details helps you get the right applicants and participants. Are you sure you do not want to add details?</h6>
    </b-modal>
    <!-- modal ends -->
  </b-row>
</template>

<script>
import {
  BCol,
  BBadge,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow, BButton,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import industryList from '@/constants/industry-list'

export default {
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    flatPickr,
    BFormFile,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      event: {
        eventId: null,
        title: null,
        type: null,
        participation: null,
        description: null,
        deliveryMode: null,
        capacity: null,
        beginTime: null,
        endTime: null,
        lifeCycleStage: [],
        industry: [],
        customerFocus: [],
        offeringsType: [],
        impactFocus: [],
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inhouseFunds: null,
        file: null,
        public: true,
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
        phases: [
          {
            title: null,
            description: null,
            objectives: [
              {
                title: null,
                description: null,
              },
            ],
            subphases: [{
              title: null,
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: null,
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [
                        {
                          title: null,
                          description: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      is_internal: true,
      phasesTab: null,
      required,
      email,
      industries: industryList,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
  },
  methods: {
    updateStatus(newstatus) {
      this.event.public = newstatus
    },
    handlenewsave() {
      if (this.event.lifeCycleStage && this.event.industry && this.event.customerFocus && this.event.offeringsType && this.event.impactFocus && this.event.lifeCycleStage.length === 0 && this.event.industry.length === 0 && this.event.customerFocus.length === 0 && this.event.offeringsType.length === 0 && this.event.impactFocus.length === 0) {
        this.$bvModal.show('submitModal')
      } else {
        this.addEvent()
      }
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addEvent()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please Wait! Creating Program...',
          icon: 'LoaderIcon',
          variant: 'info',
        },
      })
    },
    async addEvent() {
      this.mutationLoading = true
      let fileLink = null
      if (this.event.file) {
        try {
          fileLink = await this.uploadFile(this.event.file)
        } catch (error) {
          this.showAlert('Error uploading file!', 'Error')
        } finally {
          this.mutationLoading = false
        }
      }
      await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation insertProgram($input: programs_basicinfo_insert_input!) {
            insert_programs_basicinfo_one(object: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            status: 'draft',
            is_public: Boolean(this.event.public),
            organization_id_id: this.selectedOrg.orgId,
            target_participants: this.event.participation.join(', '),
            title: this.event.title,
            is_event: true,
            type: this.event.type,
            description: this.event.description,
            ideal_lifecycle_stage: this.event.lifeCycleStage.join(', '),
            capacity: this.event.capacity,
            industry: this.event.industry.join(', '),
            begin_date: this.event.beginTime,
            end_date: this.event.endTime,
            image: fileLink,
            customer_type: this.event.customerFocus.join(', '),
            offerings_type: this.event.offeringsType.join(', '),
            focus_type: this.event.impactFocus.join(', '),
            programs_deliverablelisttables: { data: this.event.deliverables.filter(deliverable => deliverable.deliverable) },
          },
        },
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          if (insert_programs_basicinfo_one.id) {
            this.showAlert('Event created successfully!', 'Success')
            this.$router.push({ name: 'manage-events' })
          } else {
            this.showAlert('Error creating event!', 'Error')
            this.mutationLoading = false
          }
        },
      })
      return this.error
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
